import React from 'react'

function OurTeam() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/12.jpg)" }}
      >
        <div className="auto-container">
          <div className="row clearfix">
            {/* <!--Title --> */}
            <div className="title-column col-lg-6 col-md-12 col-sm-12">
              <h1>Our Team</h1>
            </div>
            {/* <!--Bread Crumb --> */}
            <div className="breadcrumb-column col-lg-6 col-md-12 col-sm-12">
              <ul className="bread-crumb clearfix">
                <li>
                  <a href="/">
                    <span className="icon fas fa-home"></span> Home
                  </a>
                </li>
                <li className="active">
                  <span className="icon fas fa-arrow-alt-circle-right"></span>{" "}
                  OurTeam
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      {/* <!-- Volunter Section --> */}
      <section class="volunter-section team-page-section">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- prashant kadhao Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/prashant kadhao.jpeg" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Prashant Kadhao</a>
                  </h3>
                  <div class="designation">FOUNDER PRESIDENT  </div>
                </div>
              </div>
            </div>

            {/* <!-- Gayatri Kadhao Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/gayatri kadhao (2).png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Gayatri Kadhao</a>
                  </h3>
                  <div class="designation">Secretory</div>
                </div>
              </div>
            </div>

            {/* <!-- Ashwini Thakare Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Ashwini thakare.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Ashwini Thakare</a>
                  </h3>
                  <div class="designation">TREASURER </div>
                </div>
              </div>
            </div>

            {/* <!-- Sharda Waghmare Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sharda.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Sharda Waghmare</a>
                  </h3>
                  <div class="designation">VPMO </div>
                </div>
              </div>
            </div>

            {/* <!-- ABHISHEK TUMSARE  Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/abhishekh tumsare.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC ABHISHEK TUMSARE </a>
                  </h3>
                  <div class="designation">VPCO </div>
                </div>
              </div>
            </div>

            {/* <!-- JC SHRILEKH SHRIKHANDE  Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Shrilekh  shrikhande.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SHRILEKH SHRIKHANDE</a>
                  </h3>
                  <div class="designation">VPG&D </div>
                </div>
              </div>
            </div>

            {/* <!-- . JC MANSI BOPCHE    Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/mansi bopche.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC MANSI BOPCHE   </a>
                  </h3>
                  <div class="designation">VPPR</div>
                </div>
              </div>
            </div>

            {/* <!-- Anam Mansuri Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Anam mansuri.jpeg" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC Anam Mansuri</a>
                  </h3>
                  <div class="designation">VPTR </div>
                </div>
              </div>
            </div>

            {/* <!-- JC AMOD  CHAUDHARI  Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Amod.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        <strong>Connect with them</strong>
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC AMOD CHAUDHARI </a>
                  </h3>
                  <div class="designation">VPBO</div>
                </div>
              </div>
            </div>
            {/* <!-- JC SANJANA KASHIMKAR   Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sanjana kashimkar.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SANJANA KASHIMKAR  </a>
                  </h3>
                  <div class="designation">VPIN </div>
                </div>
              </div>
            </div>
            {/* <!-- JC GAURAV  MOHADIKAR   Block --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Gaurav Mohadikar.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC GAURAV MOHADIKAR  </a>
                  </h3>
                  <div class="designation">DITR </div>
                </div>
              </div>
            </div>

            {/* <!-- JC PRANAV  BELORKAR  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/pranav belorkar.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a>JC PRANAV BELORKAR </a>
                  </h3>
                  <div class="designation">DIG&D </div>
                </div>
              </div>
            </div>
            {/* <!-- JC ADITYA SUKHDEVE --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Aditya.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC ADITYA SUKHDEVE</a>
                  </h3>
                  <div class="designation">DICO</div>
                </div>
              </div>
            </div>

            {/* <!--  JC SHUBHANGI  TIJARE  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/shubhangi.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SHUBHANGI TIJARE </a>
                  </h3>
                  <div class="designation">DIIN </div>
                </div>
              </div>
            </div>

            {/* <!--  JC KHUSHI  DIWAN   --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Khushi.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC KHUSHI DIWAN </a>
                  </h3>
                  <div class="designation">DIMO  </div>
                </div>
              </div>
            </div>


            {/* <!--  JC DEVANSHU GOTE   --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/DEVANSHU GOTE.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC DEVANSHU GOTE   </a>
                  </h3>
                  <div class="designation">DIBO</div>
                </div>
              </div>
            </div>

            {/* <!--  JC sonalli rokade  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sonalli rokade.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SONALI ROKDE </a>
                  </h3>
                  <div class="designation">DIPR </div>
                </div>
              </div>
            </div>

            {/* <!--  JC Abhimanyu Mishra  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/Abhimanyu Mishra.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC ABHIMANYU MISHRA</a>
                  </h3>
                  <div class="designation">CO BULLETIN EDITOR  </div>
                </div>
              </div>
            </div>

            {/* <!--  JC sarthak Jichkar  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/sarthak Jichkar.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC SARTHAK JICHKAR </a>
                  </h3>
                  <div class="designation">BULLETIN EDITOR   </div>
                </div>
              </div>
            </div>
            {/* <!--  JC tanushri dhote  --> */}
            <div class="volunter-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <img src="images/team-images/tanushri.png" alt="" />
                  <div class="overlay-box">
                    <div class="overlay-inner">
                      <div class="text">
                        Connect with them
                      </div>
                      <a href="team.html" class="link-btn">
                        <span class="icon flaticon-web-link"></span>
                      </a>
                      {/* <!--Social Box--> */}
                      <ul class="social-box">
                        <li>
                          <a href="#">
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-google-plus-g"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-skype"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span class="fab fa-linkedin-in"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="lower-box">
                  <h3>
                    <a >JC TANUSHRI DHOTE  </a>
                  </h3>
                  <div class="designation">DIRECTOR LADY JAYCEE   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End team Section --> */}
    </div>
  )
}

export default OurTeam
