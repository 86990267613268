import React from "react";

function Aboutjci() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: "url(images/background/12.jpg)" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Title --> */}
            <div class="title-column col-lg-6 col-md-12 col-sm-12">
              <h1>About JCI</h1>
            </div>
            {/* <!--Bread Crumb --> */}
            <div class="breadcrumb-column col-lg-6 col-md-12 col-sm-12">
              <ul class="bread-crumb clearfix">
                <li style={{ textAlign: "justify" }}>
                  <a href="index.html">
                    <span class="icon fas fa-home"></span>
                    Home
                  </a>
                </li>
                <li class="active">
                  <span class="icon fas fa-arrow-alt-circle-right"></span> About
                  Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Welcome Section --> */}
      <section class="welcome-section">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Content Column --> */}
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <h2>
                  About
                  <span class="theme_color">JCI</span>
                </h2>
                <h3>How it all began!</h3>
                <div class="bold-text">
                  Junior Chamber International (JCI) is a worldwide federation
                  of young leaders and entrepreneurs with nearly five lakh
                  active members and millions of alumni spread across more than
                  115 countries.
                  <div class="text">
                    {/* <ul style={{fontWeight: "bold", textAlign: "justify"}}> */}
                      <li style={{ textAlign: "justify" }}>
                        <img
                          src="images/AboutJci/favicon-32x32.png"
                          alt="JCI Icon"
                        />
                        JCI's origin can be traced back to 1915 in St. Louis,
                        Missouri, USA, where Sir Henry Giessenbier and 32 other
                        young men established the Young Men's Progressive Civic
                        Association (YMPCA).
                      </li>
                      <div style={{padding:"2px"}}></div>
                      <li style={{ textAlign: "justify" }}>
                        <img
                          src="images/AboutJci/favicon-32x32.png"
                          alt="JCI Icon"
                        />
                        In 1916, the name was changed from YMPCA to "Junior
                        Citizens," commonly called "JCs," which later became
                        "Jaycees."
                      </li>
                      <div style={{padding:"2px"}}></div>
                      <li style={{ textAlign: "justify" }}>
                        <img
                          src="images/AboutJci/favicon-32x32.png"
                          alt="JCI Icon"
                        />
                        Their work so impressed the St. Louis Junior Chamber of
                        Commerce that they asked these young men in 1918 to
                        adopt the name “Junior Chamber of Commerce,” which later
                        became “Junior Chamber.”
                      </li>
                      <div style={{padding:"2px"}}></div>
                      <li style={{ textAlign: "justify" }}>
                        <img
                          src="images/AboutJci/favicon-32x32.png"
                          alt="JCI Icon"
                        />
                        By 1944, the movement had spread to 18 countries,
                        officially forming "Junior Chamber International,"
                        welcoming people between the ages of 18 and 40 years.
                      </li>
                    {/* </ul> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Video Column --> */}
            <div class="video-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <br />
                <img src="images/AboutJci/jci-vector.gif" alt="jci-vector.gif" />
              </div>
              <br />
              {/* <h6>115</h6>
              <p className="text">Spread across in countries</p> */}
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Content Column --> */}
            <div class="video-column col-lg-3 col-md-12 col-sm-12">
              <div class="inner-column">
                <img src="images/AboutJci/founder.jpg" alt="founder" />
              </div>
            </div>

            {/* <!-- Video Column --> */}
            <div class="content-column col-lg-9 col-md-12 col-sm-12">
              <div class="inner-column">
                <h4>
                  Founder
                 <h5> <span class="theme_color"><strong> "Sir Henry Giessenbier"</strong></span></h5>
                </h4>
                <p>
                  "From within the walls of the soul of this organization
                  wherein the foundation of character and good citizenship are
                  laid, I hope a message will come in the sometime of tomorrow
                  that will stir the people towards the establishment of a
                  permanent and everlasting world peace."
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
       
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Content Column --> */}
            <div class="content-column col-lg-3 col-md-12 col-sm-12">
              <div class="inner-column">
                <h2>
                  JCI Creed
                  <br />
                 <h4><span class="theme_color"><strong>We Believe</strong></span></h4> 
                </h2>
              </div>
            </div>

            {/* <!-- Video Column --> */}
            <div class="video-column col-lg-9 col-md-12 col-sm-12">
              <div class="text ">
                {/* <ul style={{ fontWeight: "bold" }}> */}
                  <li style={{ textAlign: "justify" }}>
                    <img src="images/AboutJci/favicon-32x32.png" />
                    That faith in god gives meaning and purpose to human life,
                  </li>

                  <li style={{ textAlign: "justify" }}>
                    <img src="images/AboutJci/favicon-32x32.png" />
                    That the brotherhood of man transcends the sovereignty of
                    nations,
                  </li>

                  <li style={{ textAlign: "justify" }}>
                    <img src="images/AboutJci/favicon-32x32.png" />
                    That economic justice can best be won by free men through
                    free enterprise,
                  </li>

                  <li style={{ textAlign: "justify" }}>
                    <img src="images/AboutJci/favicon-32x32.png" />
                    That government should be of laws rather than of men,
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    <img src="images/AboutJci/favicon-32x32.png" />
                    That earth’s great treasure lies in human personality,
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    <img src="images/AboutJci/favicon-32x32.png" />
                    And that service to humanity is the best work of life.
                  </li>
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Welcome Section --> */}
    </div>
  );
}

export default Aboutjci;
