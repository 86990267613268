import React from "react";

function Footer() {
  return (
    <footer
      className="main-footer"
      style={{
        backgroundImage: "url(images/background/map-pattern.png)",
      }}
    >
      <div class="auto-container">
        {/* <!--Widgets Section--> */}
        <div class="widgets-section">
          <div class="row clearfix">
            {/* <!--Footer Column--> */}
            <div class="footer-column col-lg-5 col-md-6 col-sm-12">
              <div class="footer-widget logo-widget">
                <div class="logo">
                  <a href="/">
                    <img
                      src="images/logo4.png" width={"300px"}
                      alt=""
                      title=""
                    />
                  </a>
                </div>
                <div class="text">
                  JCI India is the Second largest Member Nation of Junior
                  Chamber International. Currently we are active in more than 26
                  states and union territories across India.
                </div>
                {/* <!--Social Box--> */}
                <ul class="social-box">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61566611071468">
                      <span class="fab fa-facebook-f"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/jcinagpurfortune/">
                      <span class="fab fa-instagram"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@JCINagpurFortune">
                      <span class="fab fa-youtube"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/jcinagpufortune">
                      <span class="fab fa-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/jci-nagpur-fortune-601620330/">
                      <span class="fab fa-linkedin-in"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!--Footer Column--> */}
            <div class="footer-column col-lg-2 col-md-6 col-sm-12">
              <div class="footer-widget news-widget">
                <h2>Quick Linkss</h2>
                <ul style={{ color: "#ffff" }}>
                  <li>
                    <h6><a href="/">Home</a></h6>
                  </li>
                  <li>
                    <h6><a href="/aboutjci">About</a></h6>
                  </li>
                  <li>
                    <h6><a href="/ourteam">Team</a></h6>
                  </li>
                  <li>
                    <h6><a href="#">Events</a></h6>
                  </li>
                  <li>
                    <h6><a href="#">Programs</a></h6>
                  </li>
                  <li>
                    <h6><a href="/gallery">Gallery</a></h6>
                  </li>
                  <li>
                    <h6><a href="/downloads">Downloads</a></h6>
                  </li>
                  <li>
                    <h6><a href="/contact">Contact</a></h6>
                  </li>
                </ul>
                {/* <article class="post">
                  <figure class="post-thumb">
                    <a href="blog-single.html">
                      <img src="images/resource/news-thumb-1.jpg" alt="" />
                      <ul>
  <li><h6>Home</h6></li>
  <li><h6>About</h6></li>
  <li><h6>Home</h6></li>
  <li><h6>About</h6></li>
  <li><h6>Home</h6></li>
  <li><h6>About</h6></li>
  <li><h6>Home</h6></li>
  <li><h6>About</h6></li>
</ul>
                    </a>
                  </figure>
                  <div class="text">
                    <a href="blog-single.html">
                      p1
                    </a>
                  </div>
                  <div class="post-info">12 Dec, 2023</div>
                </article> */}

                {/* <article class="post">
                  <figure class="post-thumb">
                    <a href="blog-single.html">
                      <img src="images/resource/news-thumb-2.jpg" alt="" />
                      <h6>About</h6>
                    </a>
                  </figure>
                  <div class="text">
                    <a href="blog-single.html">
                      Take care of children for the greate serving
                    </a>
                  </div>
                  <div class="post-info">12 Dec, 2023</div>
                </article> */}
              </div>
            </div>

            {/* <!--Footer Column--> */}
            <div class="footer-column col-lg-5 col-md-6 col-sm-12">
              <div class="footer-widget info-widget">
                <h2>Contact Us</h2>
                <ul class="list-style-one">
                  <li>
                    <span class="icon fas fa-map-marker-alt"></span>Lower Ground
                    Fortune Mall, behind Maharashtra bank, Sitabuldi, Nagpur
                  </li>
                  <li>
                    <span class="icon fas fa-phone"></span>Support:{" "}
                    <a href="tel:+034-256-7850"style={{textDecoration:"none"}}>+91-9975288300</a>
                  </li>
                  <li>
                    <span class="icon fas fa-envelope-open"></span>Email:{" "}
                    <a href="mailto:jci.prashant@gmail.com"style={{textDecoration:"none"}}>
                    jci.prashant@gmail.com
                    </a>
                  </li>
                </ul>
                {/* <!--Emailed Form--> */}
                {/* <div class="emailed-form">
                  <form
                    method="post"
                    action="https://expert-themes.com/html/khidmat/contact.html"
                  >
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        value=""
                        placeholder="Enter Email"
                        required
                      />
                      <button type="submit" class="theme-btn">
                        submit
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Footer Bottom --> */}
        <div class="footer-bottom">
          <div class="clearfix">
            <div class="pull-left">
              <div class="copyright">
                Copyrights © 2024 Design By{" "}
                <a href="https://pskitservices.com/"style={{textDecoration:"none"}}>Psk Technologies Pvt. Ltd</a>
              </div>
            </div>
            <div class="pull-right">
              <ul class="footer-nav">
                <li>
                  <a href="/"style={{textDecoration:"none"}}>Home</a>
                </li>
                <li>
                  <a href="/aboutjci"style={{textDecoration:"none"}}>About</a>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
