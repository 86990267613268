import React from "react";

function AboutJciIndia() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: "url(images/background/12.jpg)" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Title --> */}
            <div class="title-column col-lg-6 col-md-12 col-sm-12">
              <h1>About JCI India</h1>
            </div>
            {/* <!--Bread Crumb --> */}
            <div class="breadcrumb-column col-lg-6 col-md-12 col-sm-12">
              <ul class="bread-crumb clearfix">
                <li style={{ textAlign: "justify" }}>
                  <a href="index.html">
                    <span class="icon fas fa-home"></span>
                    Home
                  </a>
                </li>
                <li class="active">
                  <span class="icon fas fa-arrow-alt-circle-right"></span> About
                  Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Welcome Section --> */}
      <section class="welcome-section">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Content Column --> */}

            <div class="video-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
              
               
                <img src="images/AboutJci/jci-india.png" alt />
              </div>
              <br />
              {/* <h6>115</h6>
            <p className="text">Spread across in countries</p> */}
            </div>

            {/* <!-- Video Column --> */}
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <h2>
                  About
                  <span class="theme_color"> JCI india</span>
                </h2>
                <h4>
                  Welcome to India's largest organization with young and active
                  leaders
                </h4>
                <div class="text ">
                  {/* <ul style={{ fontWeight: "bold",textAlign:"justify" }}> */}
                    <li >
                      {/* <img src="images/AboutJci/favicon-32x32.png" /> */}
                      JCI India is a voluntary organization, membership based
                      NGO working in India since 1949 for developing the
                      leadership skills of young men and women of this country.
                      It is affiliated to Junior Chamber International(JCI),a
                      worldwide federation of young leaders and entrepreneurs
                      founded in 1944, having headquarter at Chester Field USA.
                      Currently it has over 200,000 active members and more than
                      one million graduates, in over 100 countries and 6,000
                      communities.
                    </li>
                    <div style={{padding:"5px"}}></div>
                    <li style={{ textAlign: "justify" }}>
                      {/* <img src="images/AboutJci/favicon-32x32.png" /> */}
                      JCI India is the Second largest Member Nation of Junior
                      Chamber International. Currently we are active in more
                      than 26 states and union territories across India.
                    </li>
                    <div style={{padding:"5px"}}></div>
                    <li style={{ textAlign: "justify" }}>
                      {/* <img src="images/AboutJci/favicon-32x32.png" /> */}
                      The membership is offered to everybody regardless of
                      color, cast and creed between the age of 18 -40 years.
                      Junior Chamber International India is registered under
                      Societies Registration Act, Bombay Public Trust Act and
                      Income Tax Act of India.
                    </li>
                    <div style={{padding:"5px"}}></div>
                    <li style={{ textAlign: "justify" }}>
                      {/* <img src="images/AboutJci/favicon-32x32.png" /> */}
                      In the last 64 years we are able to produce thousands of
                      social and business leaders all over the country through
                      our intensive project based training activities
                    </li>
                  {/* </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Welcome Section --> */}
    </div>
  );
}

export default AboutJciIndia;
