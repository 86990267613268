import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";

const PdfDownloader = () => {
  const downloadPdf = async (url, filename) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob", // Important for handling binary data
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, filename); // Specify the filename here
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/12.jpg)" }}
      >
        <div className="auto-container">
          <div className="row clearfix">
            {/* Title */}
            <div className="title-column col-lg-6 col-md-12 col-sm-12">
              <h1>Download</h1>
            </div>
            {/* Bread Crumb */}
            <div className="breadcrumb-column col-lg-6 col-md-12 col-sm-12">
              <ul className="bread-crumb clearfix">
                <li>
                  <a href="/">
                    <span className="icon fas fa-home"></span>
                    Home
                  </a>
                </li>
                <li className="active">
                  <span className="icon fas fa-arrow-alt-circle-right"></span>
                  Download
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-3">
        <div className="mt-4 p-5 bg-basic text-white rounded">
          <div className="row">


          <div className="col-lg-4">
              <h6 style={{ color: "black" }}>Constitution & Policy Manual</h6>
              <button
                onClick={() => downloadPdf("/images/Dawnloads/1712223151-JCI-India-Constitution-and-Policy-Manual-Part-I-&-II---2024.pdf")}
                className="btn btn-primary"
              >
                Download PDF
              </button>
            </div>


            <div className="col-lg-4">
              <h6 style={{ color: "black" }}>PLAN-OF-ACTION-2024</h6>
              <button
                onClick={() => downloadPdf("/images/Dawnloads/1710593902-PLAN-OF-ACTION-2024-JCI-INDIA-final.pdf", "PLAN-OF-ACTION-2024.pdf")}
                className="btn btn-primary"
              >
                Download PDF
              </button>
            </div>



            <div className="col-lg-4">
              <h6 style={{ color: "black" }}>PRESIDENTIAL-TOOLKIT</h6>
              <button
                onClick={() => downloadPdf("/images/Dawnloads/1712223219-PRESIDENTIAL-TOOLKIT-FINAL.pdf", "PRESIDENTIAL-TOOLKIT.pdf")}
                className="btn btn-primary"
              >
                Download PDF
              </button>
            </div>

            {/* <div className="col-lg-4">
              <h6 style={{ color: "black" }}>Constitution & Policy Manual</h6>
              <button
                onClick={() => downloadPdf("/images/Dawnloads/1712223151-JCI-India-Constitution-and-Policy-Manual-Part-I-&-II---2024.pdf")}
                className="btn btn-primary"
              >
                Download PDF
              </button>
            </div> */}


          </div>
        </div>
        <br /> <br />
      </div>
    </>
  );
};

export default PdfDownloader;
