import React from "react";

function NPCorner() {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{ backgroundImage: "url(images/background/12.jpg)" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Title --> */}
            <div class="title-column col-lg-6 col-md-12 col-sm-12">
              <h1>About NP Corner </h1>
            </div>
            {/* <!--Bread Crumb --> */}
            <div class="breadcrumb-column col-lg-6 col-md-12 col-sm-12">
              <ul class="bread-crumb clearfix">
                <li>
                  <a href="index.html">
                    <span class="icon fas fa-home"></span>
                    Home
                  </a>
                </li>
                <li class="active">
                  <span class="icon fas fa-arrow-alt-circle-right"></span> About
                  Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Welcome Section --> */}
      <section class="welcome-section">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Content Column --> */}

            <div class="video-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <h4 style={{ textAlign: "justify-center", fontWeight: "bold" }}>
                  {" "}
                  <span class="theme_color">National President</span>
                </h4>
                <img src="images/AboutJci/jfsadvcrekheshsharma.png" alt />
              </div>
              <br />
              {/* <h6>115</h6>
          <p className="text">Spread across in countries</p> */}
            </div>

            {/* <!-- Video Column --> */}
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <h4 style={{ textAlign: "justify-center", fontWeight: "bold" }}>
                  <span class="theme_color">National President Message</span>
                </h4>
                <div class="text ">
                  {/* <ul style={{ textAlign: "justify" }}> */}
                  <li>Dear Jaycees of India,</li>
                  <li style={{ textAlign: "justify" }}>
                    {/* <img src="images/AboutJci/favicon-32x32.png" /> */}I am
                    honored to address you as the National President of JCI
                    India for the year 2024. As we stand at the threshold of a
                    new era of leadership and service, I am filled with immense
                    pride and anticipation for the journey that lies ahead.
                  </li>
                  <br />
                  <li style={{ textAlign: "justify" }}>
                    {/* <img src="images/AboutJci/favicon-32x32.png" /> */}
                    Our mission at JCI is rooted in the belief that young people
                    possess the power to effect positive change in their
                    communities and beyond. It is this belief that drives our
                    efforts to creating young leaders. We at JCI India will
                    further our mission in 2024 with renewed energy and
                    enthusiasm.
                  </li>
                  <br />
                  <li style={{ textAlign: "justify" }}>
                    {/* <img src="images/AboutJci/favicon-32x32.png" /> */}
                    This year, as we set on our collective quest to make a
                    difference, I urge each of you to embrace the spirit of
                    gratitude in all that we do. Let us reflect on our
                    blessings, acknowledge the contributions of others, and
                    cultivate an attitude of appreciation that permeates every
                    aspect of our lives and work.
                  </li>
                  <br />
                  {/* </ul> */}
                </div>
              </div>
            </div>

            {/*  */}

            <div class="video-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <h4 style={{ textAlign: "justify-center", fontWeight: "bold" }}>
                  {" "}
                  <span class="theme_color">National President Logo</span>
                </h4>
                <img src="images/AboutJci/nationalpresidentlogo.png" alt />
              </div>
              {/* <h6>115</h6>
          <p className="text">Spread across in countries</p> */}
            </div>

            {/* <!-- Video Column --> */}
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                {/* <h4 style={{ textAlign: "justify-center", fontWeight: "bold" }}>
                  <span class="theme_color">National President Message</span>
                </h4> */}
                <div class="text ">
                  {/* <ul style={{ textAlign: "justify" }}> */}
                  <li style={{ textAlign: "justify" }}>
                    United by a shared vision and a common purpose, we stand as
                    a beacon of hope and inspiration in a world filled with
                    challenges and opportunities. Together, let us harness the
                    collective strength of JCI India to transcend boundaries,
                    ignite passion, and create sustainable change in our lives
                    and in the communities around us.
                  </li>
                  <br />
                  <li style={{ textAlign: "justify" }}>
                    {/* <img src="images/AboutJci/favicon-32x32.png" /> */}I am
                    confident that with commitment, collaboration, and
                    unwavering dedication to the principles of active
                    citizenship, we will leave a lasting legacy of positive
                    impact that resonates for generations to come.
                  </li>
                  <br />
                  <li style={{ textAlign: "justify" }}>
                    {/* <img src="images/AboutJci/favicon-32x32.png" /> */}
                    Let us make a difference.
                  </li>
                  <br />
                  <li style={{ textAlign: "justify" }}>JFS Adv. Rekhesh Sharma</li>

                  <li style={{ textAlign: "justify" }}>National President</li>

                  <li style={{ textAlign: "justify" }}>JCI India</li>
                  <br />
                  {/* </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Welcome Section --> */}
    </div>
  );
}

export default NPCorner;
